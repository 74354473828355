import {
  Box,
  Button,
  Card,
  FormControl,
  InputAdornment,
  Stack,
  TextField,
  Typography,
  alpha,
} from '@mui/material'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import palette from '../theme/palette'
import StringUtil from '../util/StringUtil'

export interface InputFormProps {
  revenue: number
  avgTicketPrice: number
  closeRate: number
  revenueGoal: number
}

export interface ROIStats {
  newRevenue: number
  totalCost: number
  roi: number
}

// leads * close rate * avg tik price
// leads = $50/lead
// total ad spend / 50 = leads

export default function InputForm() {
  const [stage, setStage] = useState(0)
  const [stats, setStats] = useState<ROIStats | null>(null)

  function calculateROIStats(
    revenue: number,
    avgTicketPrice: number,
    closeRate: number,
    revenueGoal: number,
  ): ROIStats {
    const revenueIncreaseNeeded = revenueGoal - revenue
    const revenuePerLead = avgTicketPrice * (closeRate / 100)
    const leadsNeeded = revenueIncreaseNeeded / revenuePerLead
    let adSpend = leadsNeeded * 50 // $50 per lead (on the high end)
    if (adSpend < 2000) adSpend = 2000
    const totalCost = calculateTotalCost(adSpend)
    const roi = (revenueIncreaseNeeded / totalCost) * 100

    return {
      newRevenue: revenueIncreaseNeeded,
      totalCost,
      roi,
    }
  }

  function calculateTotalCost(adSpend: number) {
    if (adSpend <= 5000) {
      return adSpend + 2000
    }
    if (adSpend <= 8000) {
      return adSpend + 2500
    }
    return adSpend + 3000
  }

  function InputStage() {
    const {
      register,
      handleSubmit,
      formState: { errors },
    } = useForm<InputFormProps>()

    const onSubmit = (data: InputFormProps) => {
      setStage(1)
      const revenue = parseFloat(data.revenue.toString())
      const avgTicketPrice = parseFloat(data.avgTicketPrice.toString())
      const closeRate = parseFloat(data.closeRate.toString())
      const revenueGoal = parseFloat(data.revenueGoal.toString())
      setStats(calculateROIStats(revenue, avgTicketPrice, closeRate, revenueGoal))
    }

    return (
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack gap={2}>
          <Typography variant='h5' pb={1}>
            Calculate your ROI
          </Typography>
          <FormControl fullWidth variant='filled'>
            <Typography variant='body2' color='textSecondary' mb={1}>
              What's your current monthly revenue?
            </Typography>
            <TextField
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              {...register('revenue', { required: true })}
              error={!!errors.revenue}
              helperText={errors.revenue ? 'Revenue is required' : ''}
              placeholder='20,000'
            />
          </FormControl>
          <FormControl fullWidth variant='filled'>
            <Typography variant='body2' color='textSecondary' mb={1}>
              What's your average ticket price?
            </Typography>
            <TextField
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              {...register('avgTicketPrice', { required: true })}
              error={!!errors.avgTicketPrice}
              helperText={errors.avgTicketPrice ? 'Average ticket price is required' : ''}
              placeholder='600'
            />
          </FormControl>
          <FormControl fullWidth variant='filled'>
            <Typography variant='body2' color='textSecondary' mb={1}>
              What % of leads do you think you can close?
            </Typography>
            <TextField
              type='number'
              InputProps={{
                endAdornment: <InputAdornment position='end'>%</InputAdornment>,
              }}
              {...register('closeRate', { required: true })}
              error={!!errors.closeRate}
              helperText={errors.closeRate ? 'Close rate is required' : ''}
              placeholder='20'
            />
          </FormControl>
          <FormControl fullWidth variant='filled'>
            <Typography variant='body2' color='textSecondary' mb={1}>
              What do you want to grow your monthly revenue to?
            </Typography>
            <TextField
              type='number'
              InputProps={{
                startAdornment: <InputAdornment position='start'>$</InputAdornment>,
              }}
              {...register('revenueGoal', { required: true })}
              error={!!errors.revenueGoal}
              helperText={errors.revenueGoal ? 'Revenue goal is required' : ''}
              placeholder='30,000'
            />
          </FormControl>
          <Button type='submit' variant='contained' sx={{ mt: 2 }}>
            Calculate
          </Button>
        </Stack>
      </form>
    )
  }

  function ResultsStage() {
    if (!stats) return null
    return (
      <Stack gap={2} sx={{ textAlign: 'center' }}>
        <Stack>
          <Typography color='text.secondary'>Monthly revenue increase</Typography>
          <Typography variant='h5'>${StringUtil.numberFormat(stats.newRevenue)}</Typography>
        </Stack>
        <Stack>
          <Typography color='text.secondary'>
            Your total monthly cost with us
            <br />
            (ad spend + fees)
          </Typography>
          <Typography variant='h5'>${StringUtil.numberFormat(stats.totalCost)}</Typography>
        </Stack>
        <Stack>
          <Typography color='text.secondary'>Return on investment</Typography>
          <Typography variant='h3' color='primary'>
            {StringUtil.numberFormat(stats.roi)}%
          </Typography>
          <Typography variant='h5' color='primary'>
            ({StringUtil.numberFormat(stats.roi / 100, 1)}X)
          </Typography>
        </Stack>
        <Stack gap={1}>
          <Button
            variant='contained'
            sx={{ mt: 1 }}
            href='https://info.movingengine.io/increase-revenue450449'
          >
            Get in touch!
          </Button>
          <Button onClick={() => setStage(0)}>Calculate again</Button>
        </Stack>
      </Stack>
    )
  }

  return (
    <Box
      sx={{
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <Box
        sx={{
          background: alpha(palette.light.primary.main, 0.1),
          borderRadius: '50%',
          width: 400,
          height: 400,
          position: 'absolute',
          animation: 'pulse 2s infinite ease-in-out',
          '@keyframes pulse': {
            '0%': {
              transform: 'scale(1)',
              boxShadow: '0 0 0 0 rgba(0, 195, 255, 0.1)',
            },
            '50%': {
              transform: 'scale(1.05)',
              boxShadow: '0 0 20px 20px rgba(255, 255, 255, 0)',
            },
            '100%': {
              transform: 'scale(1)',
              boxShadow: '0 0 0 0 rgba(255, 255, 255, 0)',
            },
          },
        }}
      />
      <Card sx={{ p: 4, width: 320, zIndex: 1, position: 'relative' }}>
        {stage === 0 && <InputStage />}
        {stage === 1 && <ResultsStage />}
      </Card>
    </Box>
  )
}
