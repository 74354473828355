import { Stack } from '@mui/material'
import logo from '../assets/logo.png'
import InputForm from '../components/InputForm'

export default function HomeScreen() {
  return (
    <Stack sx={{ alignItems: 'center', p: 5, px: 3, gap: 5, width: 'screen', overflow: 'hidden' }}>
      <img src={logo} style={{ maxWidth: 400 }} />
      <InputForm />
    </Stack>
  )
}
