export default class StringUtil {
  static numberFormat(number?: number, decimals?: number, estimateLargeNumbers = false): string {
    /*
    Format the number with commas, abbreviate large numbers with k or M, and allow optional decimal places.
    */

    if (number === undefined || number === null) return '-'

    const absNumber = Math.abs(number)

    // Less than 1
    if (absNumber < 1 && number !== 0) {
      return number.toLocaleString(undefined, { maximumFractionDigits: decimals ?? 2 })
    }

    // Less than 5
    if (absNumber < 5) {
      return number.toLocaleString(undefined, { maximumFractionDigits: decimals ?? 2 })
    }

    // All other numbers
    return number.toLocaleString(undefined, { maximumFractionDigits: decimals ?? 0 })
  }

  static dollarFormat(number?: number): string {
    if (number === undefined) return '-'
    return '$' + this.numberFormat(number)
  }

  static camelCaseToTitleCase(str: string): string {
    /*
    Convert camelCase to Title Case
    */

    return str.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())
  }

  static shorten(str: string, maxLength: number): string {
    return str.length > maxLength ? str.substring(0, maxLength - 3) + '...' : str
  }
}
