import { BrowserRouter, Route, Routes } from 'react-router-dom'
import HomeScreen from './screens/HomeScreen'
import ThemeProvider from './theme'

// Redeploy

export default function App() {
  return (
    <BrowserRouter>
      <ThemeProvider>
        <Routes>
          <Route path={'/'} element={<HomeScreen />} />
        </Routes>
      </ThemeProvider>
    </BrowserRouter>
  )
}
